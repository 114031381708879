
<template>
   <div>

<!--
      <div  style="position:absolute;right:50px;top: 0px; width:50%;padding: 10px 20px 10px 20px;  border: 1px solid #c0c0c0;border-radius: 10px;" > -->
      <div  style="position:absolute;right:0px;top: 5px; width: 194px; padding: 10px 0;" >
  <!--   <button type="button" @click="play()"><i class="mdi" :class="{'mdi-play': !is_playing, 'mdi-pause':is_playing}"></i></button>
    <button type="button" @click="stop()"><i class="mdi mdi-stop"></i></button>
      <span class="inner-bg">Y Axis</span> -->
      <span :style="control">
        <input type="radio" v-model="vchart" value="0" id="fixed"> <label for="fixed" class="status__label">Fixed</label>
        <input type="radio" v-model="vchart" value="1" id="proportional"><label for="proportional" class="status__label">Proportional</label>
      </span>
    </div>
    <div  :style="{height: height+'px'}">
        <line-chart ref="chart" v-bind:chartData="chartData" v-bind:yopt="yopt" v-bind:xdata="xdata" v-bind:xmax="xmax" v-bind:ymax="ymax" v-if="loaded" v-bind:class="size"></line-chart>
    </div>
  </div>
</template>

<script>
//import * as d3 from 'd3'
import LineChart from '@/components/chart/LineChart'
//import {Line} from 'vue-chartjs'

export default {
    name: 'FFTChart1',
 //   extends: Line,
    components: {LineChart },
    props: [
        'height',
        'url'
    ],
    watch: {
        vchart : function() {
             this.xdata = [];
             this.datacollection= null;
             this.loaded=false;
              this.$http.get(this.aurl).then((res) => {
               if( res.data !=="")
                  this.chartData = res.data.slice(0, this.$store.setting.wave.frequency.x);
               else
                  this.chartData =[] ;
            });
          // this.redraw();
        },
        chartData: function() {
                   this.redraw();
        },
        url() {
            if(this.url==='') return
           // datacollection: null,
           // this.chartData= [];
            this.xdata = [];
            this.datacollection= null;
            this.loaded=false;
            var url_a =this.url.split('#');
          //  this.vchart = url_a[1] ;
            this.aurl = url_a[0] ;
            this.$http.get(url_a[0]).then((res) => {
               if( res.data !=="")
                  this.chartData = res.data.slice(0, this.$store.setting.wave.frequency.x);
               else
                  this.chartData =[] ;
            });
        }
    },
    created () {
      //this.fillData()
    },
     data () {
      return {
        datacollection: null,
        chartData: [],
        size:'',
        xdata : [],
        ymax : 1,
        xmax : 10,
        loaded: false,
        yopt : null,
        vchart : '0',
        aurl : '',
        control : 'float : left; margin-left: -100px;margin-top: -5px;',
      }
    },
    methods: {

       redraw () {

             this.ymax = parseInt(this.$store.setting.wave.frequency.y) ;
             if(this.vchart === '0'){
                this.ymax = parseInt(this.$store.setting.wave.frequency.y) ;
                 this.yopt =  {
                      beginAtZero: true,
                      max: this.ymax,
                  //    max: 10,
                      min: 0,
                      stepSize  : this.ymax/5
                    } ;
             }else{
                this.yopt = {  beginAtZero: true  } ;
             }


             this.xmax = parseInt(this.$store.setting.wave.frequency.x) ;
             this.xdata=[];
               for (var i = 0;  i < this.chartData.length ; ++i) {
                 this.xdata.push(i);
               }
              this.loaded = true;
            //  this.update();
           //   this.$data._chart.update()
           // this.chartData =  cData ;
            //this.$refs.chart.update();

      }



    },
  mounted () {
        window.addEventListener('resize', this.resize);
        let cur_page = ""+document.location.href ;
        if(cur_page.indexOf("leak-analysis1") !== -1) {
           this.control = 'float : left; margin-left: 0px;'
           this.size= 'firstClass1'
        }else{
          this.size = 'firstClass'
        }
        if(this.url==='') return
         this.aurl = this.url;
         
        this.$http.get(this.url).then((res) => {
            if( res.data !=="")
               this.chartData = res.data.slice(0, this.$store.setting.wave.frequency.x);
            else
               this.chartData =[] ;
        });
    
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.resize)
    }

}
</script>

<style scoped>
.firstClass{
   height: 180px;
   padding-top: 0px;
 /*    width: 700px;    */
  }
.firstClass1{
   height: 152px;
   padding-top: 0px;
 /*    width: 700px;    */
  }

.inner-bg {
     float : left;
     /* 공통 속성값 지정 */

     display: inline-block;
      /* 인라인요소를 인라인블록 요소로 */
     height: 30px;
        /* 이미지 공간을 확보하기 위해 높이값 지정 */

     background: #c0c0c0;
     padding-left:10px;
     padding-right: 10px;

    }

</style>
